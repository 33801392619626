import React, { ReactNode } from 'react';
import { Text, Spacer } from '@koalafi/component-library';
import Button from '../button/Button';

type PaymentSelectionBaseProps = {
  header: ReactNode;
  subText: ReactNode;
  primaryButtonText: string;
  primaryButtonAction: () => void;
  secondaryButtonAction: () => void;
  isLoading?: boolean;
};

const PaymentSelectionBase: React.FC<PaymentSelectionBaseProps> = ({
  header,
  subText,
  primaryButtonText,
  primaryButtonAction,
  secondaryButtonAction,
  isLoading = false,
}) => {
  const plaidLoading = false;

  const secondaryButton = (
    <Text size="md" alignText="left" weight="light">
      Alternatively, you can{' '}
      <div className="text-accents-primary underline inline cursor-pointer" onClick={secondaryButtonAction} data-testid="payment-selection-manual">
        enter your bank account information manually
      </div>
    </Text>
  );
  return (
    <div>
      {/* Header */}
      <Text size="3xl" alignText="left" weight="extrabold">
        {header}
      </Text>
      <Spacer size="md" />
      <Text size="md" alignText="left" weight="light">
        {subText}
      </Text>
      <Spacer size="lg" />
      <Button type="submit" disabled={plaidLoading} onClick={primaryButtonAction} dataLoading={isLoading}>
        {primaryButtonText}
      </Button>
      <Spacer size="sm" />
      {secondaryButton}
    </div>
  );
};

export default PaymentSelectionBase;
