import React, { useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { FieldProps } from 'formik';
import Cleave from 'cleave.js/react';
import { CleaveOptions } from 'cleave.js/options';
import { colors, fontFamilies } from '../baseStyle/Variables';
import { FloatingLabel, ValidationError } from './common';
import Tooltip from '../tooltipV2/Tooltip';

const TextInputWrapper = styled.div<{ valid: boolean; tooltip: boolean }>`
  color: ${colors.white};
  position: relative;
  height: 100%;
  margin-top: 2rem;

  & input {
    display: block;
    box-sizing: border-box;
    font-family: ${fontFamilies.interRegular};
    border: none;
    border-radius: 0;
    padding: 0;
    padding-right: ${(props: any) => (props.tooltip ? '2rem' : '0')};
    border-bottom: 2px solid ${(props: any) => (props.valid ? colors.darkMidGray : colors.errorRed)};
    font-size: 1.5rem;
    line-height: 2rem;
    width: 100%;
    transition: all 0.5s;
    color: ${colors.darkBlue};
    height: calc(2rem + 2px);

    &:required {
      box-shadow: none;
    }

    &:focus {
      outline: none;
      border-bottom: 2px solid ${colors.koalafiBlue};
    }

    &::placeholder {
      color: ${colors.middleGrey};
      opacity: 0;
      transition: opacity 0.5s 0.3s;
    }

    &:focus::placeholder {
      opacity: 1;
    }
  }
`;

interface IProps {
  generalErrorMessage?: Function;
  label: string;
  cleaveOptions: CleaveOptions;
  id: string;
  className?: string;
  type?: string;
  autoFocus?: boolean;
  autoComplete?: string;
  onBlur?: any;
  onChange?: any;
  mask?: boolean;
  trim?: boolean;
  useRawValue?: boolean;
  disabled?: boolean;
  fsExclude?: boolean;
  tooltipContent?: React.ReactElement;
  placeholder?: string;
  clearErrorOnChange?: boolean;
  inputMode?: 'text' | 'email' | 'search' | 'tel' | 'url' | 'none' | 'numeric' | 'decimal' | undefined;
}

const TextField: React.FC<FieldProps & IProps> = ({
  id,
  className,
  field,
  form: { errors, setFieldValue },
  cleaveOptions,
  label,
  type = 'text',
  onBlur,
  onChange,
  autoFocus,
  autoComplete,
  mask,
  trim = true,
  useRawValue,
  disabled,
  generalErrorMessage,
  fsExclude,
  tooltipContent,
  placeholder,
  clearErrorOnChange = true,
  inputMode = 'text',
}) => {
  const [restDate, setRestDate] = useState('');
  const [focused, setFocused] = useState(false);
  const maskedValue = field.value ? field.value.replace(/./g, '*') : '';
  const ref = useRef(null);

  // GROSS CODE ALERT
  // I have to copy the error messages so that the "disapear" transition is allowed to
  // finish.  The copy is used instead of the prop, b/c the prop immediately is emptied
  // if validation passes and does not show the "disappear" transition, instead abruptly
  // removes the message from the screen.
  const [genErrMsgCopy, setGenErrMsgCopy] = useState('');
  useEffect(() => {
    if (generalErrorMessage && generalErrorMessage()) {
      setGenErrMsgCopy(generalErrorMessage());
    }
  }, [generalErrorMessage]); // eslint-disable-line react-hooks/exhaustive-deps

  const [fieldErrMsgCopy, setFieldErrMsgCopy] = useState('');
  useEffect(() => {
    if (errors[field.name]) {
      setFieldErrMsgCopy(errors[field.name]?.toString() ?? '');
    }
  }, [errors[field.name]]); // eslint-disable-line react-hooks/exhaustive-deps

  const allowForEditing = (e: any) => {
    if (focused) {
      return;
    } else if (!disabled) {
      // @ts-ignore
      ref.current.focus();
      setFocused(true);
    }
  };

  const DateFormat = styled.div`
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    span {
      display: inline-block;
      font-family: ${fontFamilies.interRegular};
      font-size: 1.5rem;
      line-height: 2rem;
      color: ${colors.middleGrey};

      &.hidden {
        opacity: 0;
      }
    }
  `;

  // handle undefined cleaveOptions
  let freeInput = {
    blocks: [99999],
    delimiter: '',
  };

  interface OnBlurEvent<T> extends React.FocusEvent<T> {
    target: { rawValue: string } & EventTarget & T;
  }

  const isValid = !errors[field.name] && (!generalErrorMessage || !generalErrorMessage());

  return (
    <div onClick={allowForEditing}>
      <TextInputWrapper valid={isValid} tooltip={!!tooltipContent} className={className}>
        <FloatingLabel focused={focused} valid={isValid} empty={!field.value} htmlFor={id} data-testid={`${id}-label`} id={`${id}-label`}>
          {label}
        </FloatingLabel>
        <>
          <Cleave
            {...field}
            id={id}
            type={type || 'text'}
            value={mask && !focused ? maskedValue : field.value}
            options={cleaveOptions === undefined ? freeInput : cleaveOptions}
            autoComplete={autoComplete}
            // required
            aria-labelledby={`${field.name}-label`}
            onBlur={(event: OnBlurEvent<HTMLInputElement>) => {
              // Trim leading and trailing whitespace
              if (trim) setFieldValue(field.name, event.target.value.trim());
              field.onBlur(event);
              if (onBlur) onBlur();
              setFocused(false);
              //Use the unformatted value
              if (useRawValue) setFieldValue(field.name, event.target.rawValue);
            }}
            onFocus={() => {
              setFocused(true);
            }}
            placeholder={placeholder}
            autoFocus={autoFocus}
            onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => {
              if ((type === 'tel' || type === 'custDate') && !/^\d*$/.test(e.key)) {
                return e.preventDefault();
              }
            }}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (type === 'custDate' && placeholder) {
                setFieldValue(field.name, e.currentTarget.value);

                const mask = placeholder;
                if (e.currentTarget.value) {
                  setRestDate(mask.substring(e.currentTarget.value.length));
                } else {
                  setRestDate('');
                }
              }
              if (clearErrorOnChange) {
                delete errors[field.name];
              }
              if (onChange) onChange();
              field.onChange(e);
            }}
            disabled={disabled}
            // Setting ref this way because Cleavejs only supports older approach
            htmlRef={(i) => (ref.current = i)}
            // adds fs-exclude to text input for full story field masking
            {...(fsExclude && { className: 'fs-exclude' })}
            inputMode={inputMode}
            aria-invalid={!isValid}
            aria-describedby={`${id}-genErrMsg ${id}-fieldErrMsg`}
          />

          {tooltipContent && <Tooltip id={id} tooltipContent={tooltipContent} place="left" />}
          {type === 'custDate' && (
            <DateFormat>
              <span className="hidden">{field.value}</span>
              <span>{restDate}</span>
            </DateFormat>
          )}
        </>
        <ValidationError role="alert" id={`${id}-genErrMsg`} className={generalErrorMessage && generalErrorMessage() ? 'visible' : 'hidden'}>
          {genErrMsgCopy}
        </ValidationError>
        <ValidationError role="alert" id={`${id}-fieldErrMsg`} className={errors[field.name] ? 'visible' : 'hidden'}>
          {fieldErrMsgCopy}
        </ValidationError>
      </TextInputWrapper>
    </div>
  );
};

export default TextField;
