import { assign, createMachine } from 'xstate';

import {
  NewApplicationEvents,
  NewApplicationMachineContext,
  NewApplicationMachineEvents,
  NewApplicationMachineGuards,
  NewApplicationMachineStates,
  NewApplicationStates,
} from './types/newApplication.types';
import { sendParent } from 'xstate/lib/actions';
import { EcommMachineEvents } from './types/ecommMachine.types';
import { AppMode } from '../stores/order.store';
import { NavigationSections } from '../constants';

export default createMachine<NewApplicationMachineContext, NewApplicationMachineEvents, NewApplicationMachineStates>(
  {
    id: 'NewApplication',
    initial: NewApplicationStates.BillingAddress,
    context: {
      applyErrors: [],
      appMode: null,
      order: null,
      // the application is from the apply result and different from the application in the order
      // this drives the events changes to approved
      application: null,
    },
    states: {
      [NewApplicationStates.BillingAddress]: {
        on: {
          [NewApplicationEvents.NEXT]: {
            target: NewApplicationStates.ApplicationApply,
            actions: assign({
              applyErrors: (context, event) => event.data.applyErrors,
              appMode: (context, event) => event.data.appMode,
              order: (context, event) => event.data.order,
              application: (context, event) => event.data.application,
            }),
          },
          [NewApplicationEvents.BACK]: {
            // type: 'final',
            actions: sendParent((context, event) => ({
              type: EcommMachineEvents.BACK,
            })),
          },
          [NewApplicationEvents.VIEW_CUSTOMER_ID_PROGRAM]: {
            target: 'CustomerIdProgram',
          },
          [NewApplicationEvents.VIEW_TERMS_AND_CONDITIONS]: {
            target: 'TermsAndConditions',
          },
          [NewApplicationEvents.VIEW_PREQUALIFICATION_NOTICE]: {
            target: 'PrequalificationNotice',
          },
          [NewApplicationEvents.VIEW_AUTHORIZED_USER_TERMS]: {
            target: 'AuthorizedUserTerms',
          },
        },
        meta: {
          navigationSection: NavigationSections.CHECK_ELIGIBILITY,
          canGoBackViaHeader: true,
        },
      },
      [NewApplicationStates.CustomerIdProgram]: {
        on: {
          [NewApplicationEvents.NEXT]: {
            target: NewApplicationStates.BillingAddress,
          },
        },
      },
      [NewApplicationStates.TermsAndConditions]: {
        on: {
          [NewApplicationEvents.NEXT]: {
            target: NewApplicationStates.BillingAddress,
          },
        },
      },
      [NewApplicationStates.PrequalificationNotice]: {
        on: {
          [NewApplicationEvents.NEXT]: {
            target: NewApplicationStates.BillingAddress,
          },
        },
      },
      [NewApplicationStates.AuthorizedUserTerms]: {
        on: {
          [NewApplicationEvents.BACK]: {
            target: NewApplicationStates.BillingAddress,
          },
        },
      },
      [NewApplicationStates.ApplicationApply]: {
        id: 'application_apply',
        initial: NewApplicationStates.Loading,
        states: {
          [NewApplicationStates.Loading]: {
            always: [
              { target: '#NewApplication.LoanDeclined', cond: 'LoanDecline' },
              { target: '#NewApplication.LeaseDeclined', cond: 'LeaseDecline' },
              { target: '#NewApplication.LTOIneligible', cond: 'LTOIneligible' },
              { target: '#NewApplication.GeneralError', cond: 'GeneralApplyErrors' },
              { target: '#NewApplication.BillingAddressErrors', cond: 'SoftDecline' },
              { target: '#NewApplication.Approval', cond: 'AppApproved' },
            ],
            meta: {
              navigationSection: NavigationSections.HIDE_PROGRESS_BAR,
            },
          },
        },
      },
      [NewApplicationStates.Approval]: {
        on: {
          [NewApplicationEvents.NEXT]: {
            actions: [
              sendParent((context, event) => ({
                type: EcommMachineEvents.EXISTING_APPLICATION,
                data: { appMode: event?.data?.appMode, order: event?.data?.order, skipApproval: true },
              })),
            ],
          },
        },
        meta: {
          navigationSection: NavigationSections.HIDE_PROGRESS_BAR,
        },
      },
      [NewApplicationStates.LoanDeclined]: {},
      [NewApplicationStates.LeaseDeclined]: {},
      [NewApplicationStates.LTOIneligible]: {},
      [NewApplicationStates.GeneralError]: {
        // type: 'final',
        meta: {
          navigationSection: NavigationSections.HIDE_PROGRESS_BAR,
        },
      },
      [NewApplicationStates.BillingAddressErrors]: {
        on: {
          [NewApplicationEvents.BACK]: {
            target: NewApplicationStates.BillingAddress,
          },
        },
        meta: {
          navigationSection: NavigationSections.CHECK_ELIGIBILITY,
        },
      },
    },
    schema: {
      context: {} as any,
      events: {} as NewApplicationMachineEvents,
      guards: {} as NewApplicationMachineGuards,
    },
    predictableActionArguments: true,
    preserveActionOrder: true,
  },
  {
    actions: {},
    guards: {
      LoanDecline: (context, event) => {
        if (context.applyErrors) {
          return context.applyErrors.some((err) => err.__typename === 'HardDecline') && context.appMode === AppMode.loan;
        }
        return false;
      },
      LeaseDecline: (context, event) => {
        if (context.applyErrors) {
          return context.applyErrors.some((err) => err.__typename === 'HardDecline') && context.appMode === AppMode.lease;
        }
        return false;
      },
      LTOIneligible: (context, event) => {
        if (context.applyErrors) {
          return context.applyErrors.some((err) => err.__typename === 'LeaseRestricted');
        }
        return false;
      },
      SoftDecline: (context, event) => {
        if (context.applyErrors) {
          return context.applyErrors.some((err) => err.__typename === 'SoftDecline');
        }
        return false;
      },
      GeneralApplyErrors: (context, event) => {
        if (context.applyErrors) {
          // This handles __typename === 'FatalError' || 'Ineligible'
          const otherErrors = context.applyErrors.some((err) => {
            // I think this check could be simplified. Guard conditions are checked in order,
            // so we already know that SoftDecline, LeaseRestricted, and HardDecline are not
            // in the array.  So all we should need to check for is CartValidation here
            return (
              err.__typename !== 'SoftDecline' &&
              err.__typename !== 'LeaseRestricted' &&
              err.__typename !== 'HardDecline' &&
              err.__typename !== 'CartValidation'
            );
          });
          return context.applyErrors.length > 0 && otherErrors;
        }
        return false;
      },
      AppApproved: (context, event) => {
        // If the error is cart validation, still want to show approval page...
        if (context.applyErrors.length > 0) {
          return context.applyErrors.some((err) => err.__typename === 'CartValidation');
        } else if (!context.applyErrors || context.applyErrors.length === 0) {
          return (
            context?.application?.lease?.status === 'preApproved' ||
            (context?.application?.lease?.isOtb && context.order?.application?.lease?.status === 'approved') ||
            context?.application?.loan?.status === 'approved'
          );
        }
        return false;
      },
    },
  },
);
